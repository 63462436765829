import { ContainerModuleLoader } from '@wix/thunderbolt-ioc'
import { LifeCycle } from '@wix/thunderbolt-symbols'
import { TpaMessageContextPicker } from './tpaMessageContextPicker'
import {
	MasterPageTpaPropsCacheSymbol,
	TpaSectionSymbol,
	TpaContextMappingSymbol,
	TpaSrcBuilderSymbol,
} from './symbols'
import { TpaPropsCacheFactory } from './tpaPropsCache'
import { TpaSrcBuilder } from './tpaSrcBuilder'
import { TpaContextMappingFactory } from './tpaContextMapping'
import { TpaSection } from './tpaSection'

export const site: ContainerModuleLoader = (bind) => {
	bind(LifeCycle.AppDidMountHandler).to(TpaMessageContextPicker)
	bind(MasterPageTpaPropsCacheSymbol).to(TpaPropsCacheFactory)
	bind(TpaContextMappingSymbol).to(TpaContextMappingFactory)
	bind(TpaSrcBuilderSymbol).to(TpaSrcBuilder)
	bind(TpaSectionSymbol).to(TpaSection)
}

export { TpaHandlersManagerSymbol, TpaSectionSymbol, name } from './symbols'
export { MasterPageTpaPropsCacheSymbol, TpaSrcBuilderSymbol, TpaContextMappingSymbol }
export * from './types'
